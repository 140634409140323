/* eslint-disable */
/* global window:true */
import React from 'react';
import PropTypes from 'prop-types';
import TranslationService from '../translations';
import LoggedInUserSettings from '../common/components/LoggedInUserSettings';

class ParticipantSettings extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      navigation, baseApiUrl, context, assets, baseUrlPrivateAssets
    } = this.props;
    const translation = TranslationService.getTranslations(context.interfaceLocale);
    return (
      <LoggedInUserSettings
        navigation={navigation}
        translation={translation}
        baseApiUrl={baseApiUrl}
        context={context}
        assets={assets}
        baseUrlPrivateAssets={baseUrlPrivateAssets}
      />
    );
  }
}

ParticipantSettings.propTypes = {
  baseApiUrl: PropTypes.string.isRequired,
  context: PropTypes.shape({
    interfaceLocale: PropTypes.string.isRequired,
    primaryLocale: PropTypes.string.isRequired,
    eventEditionId: PropTypes.string.isRequired
  }).isRequired,
  navigation: PropTypes.object.isRequired,
  assets: PropTypes.object.isRequired,
  baseUrlPrivateAssets: PropTypes.string.isRequired
};

export default ParticipantSettings;
