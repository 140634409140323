import Request from './request';
import ServiceResultFactory from './ServiceResultFactory';

const getUserProperties = ({ baseApiUrl, participationId, locale }) => {
  const query =
  `{
    participation(participationId: "${participationId}", locale: "${locale}") 
    {
        firstName,
        exhibitingOrganisation{id, package},
        photoKey
    }
  }`;
  return Request.graphqlRequest(baseApiUrl, query)
    .then(response => response.body)
    .then(ServiceResultFactory.fromSuccess)
    .catch(result => ServiceResultFactory.fromErrorCode(locale, result, false));
};

export default {
  getUserProperties
};
