import React from 'react';

// eslint-disable-next-line max-len
const EmperiaIcon = () => (
  // <span>Ankur</span>
  <svg version="1.1" viewBox="0 0 62 62" xmlns="http://www.w3.org/2000/svg" width="15px">
    <title>Group</title>
    <g fill="none" fillRule="evenodd">
      <g fill="#fff" fillRule="nonzero">
        <polygon id="Path" points="0 18.286 6.8163 16.005 6.8163 6.8015 34.541 6.8015 34.541 0 0 0 0 18.196" />
        <polygon id="Path" points="43.636 0 45.916 6.8163 55.12 6.8163 55.12 34.541 61.922 34.541 61.922 0 43.726 0" />
        <polygon id="a" points="18.286 61.918 16.005 55.102 6.8015 55.102 6.8015 27.378 0 27.378 0 61.918 18.196 61.918" />
        <polygon points="61.918 43.636 55.102 45.916 55.102 55.12 27.378 55.12 27.378 61.922 61.918 61.922 61.918 43.726" />
      </g>
    </g>
  </svg>
);

export default EmperiaIcon;
