import ServiceResultFactory from './ServiceResultFactory';
import Request from '../../common/api/request';
import logger from '../../logger';

const getExhibitorFromEmperia = (baseApiUrl, showId, platformExhibitorId, interfaceLocale) => {
  const url = `${baseApiUrl}api/v1/exhibitors`;
  return Request
    .get(url, { showId, platformExhibitorId })
    .then(result => ServiceResultFactory.fromSuccess({ exhibitor: result.body.exhibitors[0] }))
    .catch(error => ServiceResultFactory.fromError(interfaceLocale, error, false, ''));
};
const getShowFromEmperia = (baseApiUrl, eventEditionId, exhibitorId, interfaceLocale) => {
  const url = `${baseApiUrl}api/v1/lead-capture-shows`;
  return Request.get(url, { platformShowId: eventEditionId })
    .then(result => ServiceResultFactory.fromSuccess({ show: result.body[0] }))
    .catch(error => ServiceResultFactory.fromError(interfaceLocale, error, false, ''));
};
const shouldShowEmperiaTile = (baseApiUrl, eventEditionId, exhibitingOrganisationId, locale) => getShowFromEmperia(baseApiUrl, eventEditionId)
  .then(emperiaResponse => getExhibitorFromEmperia(baseApiUrl, emperiaResponse?.show?.id, exhibitingOrganisationId, locale)
    .then((exhibitorResult) => {
      const showLeads = exhibitorResult?.exhibitor?.emperiaEntitlements > 0;
      logger.logInfo(`exhibitorResult:${JSON.stringify(showLeads)}`, '', baseApiUrl);
      logger.logInfo(`exhibitorResult:${JSON.stringify(exhibitorResult)}`, '', baseApiUrl);
      return ServiceResultFactory.fromSuccess({ showLeads: exhibitorResult?.exhibitor?.emperiaEntitlements > 0 });
    })
    .catch((error) => {
      logger.logInfo(`exhibitorError:${JSON.stringify(error)}`, '', baseApiUrl);
      return ServiceResultFactory.fromError(locale, error, false, '');
    }));
export default {
  shouldShowEmperiaTile
};
