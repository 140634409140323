/* eslint-disable */
import React from 'react';
import { authController } from '@reedexpo/authorization-component-ui';
import PropTypes from 'prop-types';
import jsonwebtoken from 'jsonwebtoken';
import Classes from '../Classes';
import UserService from '../api/UserService';
import UserProperties from '../api/UserProperties';
import SpinnerWithMessage from '../components/SpinnerWithMessage';
import Utils from '../Utils';
import logger from '../../logger';
import Tags from '../../common/dtmTags';
import authorizationStore from '../../common/api/authorizationStore';
import Image from './Image';
import CommonServices from '../api/CommonService';
import EmperiaIcon from './EmperiaIcon';
import emperiaService from '../api/EmperiaService';
import { Claims } from '../Constants';
import FeatureFilterService from '../../feature-filters/FeatureFilterService';
import featureToggle from '../featureToggle/featureToggles';

class LoggedInUserSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      participations: null,
      isCurrentEEAdmin: false,
      adminExhibitingOrganisation: null,
      userName: null,
      userPackage: null,
      isNavOpened: false,
      shouldShowExhibitorDashboard: false,
      shouldShowEmperiaLeads: false,
      desktopUrl: this.props.assets.defaultParticipantPhotoUrl,
      mobileUrl: this.props.assets.defaultParticipantPhotoMobileUrl,
      profileImageNotAvailable: null
    };
  }



  componentDidMount() {
    const {
      baseApiUrl,
      context,
      assets,
      baseUrlPrivateAssets,
      navigation
    } = this.props;
    authorizationStore.getToken().then((token) => {
      const hasAdminClaim = authorizationStore.hasAdminClaim(token);
      if (!hasAdminClaim) {
        this.noAdminClaim(context, navigation);
      } else {
        this.hasAdminClaim(context, baseApiUrl);
      }

      const userId = jsonwebtoken.decode(token).sub;
      this.getUserParticipation(context, navigation, baseApiUrl, userId, hasAdminClaim, assets, baseUrlPrivateAssets);
    });
    document.addEventListener('click', this.handleClickOutside, true);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true);
  }

  noAdminClaim = (context, navigation) => {
    authController.hasClaim(true, Claims.RxAtlasEe, context.eventEditionId).then((hasEEClaim) => {
      if (!hasEEClaim && Utils.getLocationHref() === navigation.customerHubUrlFormat) {
        Utils.setLocationHref(window.location.origin);
      }
    });
  }

  hasAdminClaim = (context, baseApiUrl) => {
    UserService.getEventEditionListForAdmin(baseApiUrl, context.interfaceLocale, context.eventEditionId).then((eventEditionList) => {
      const matchedEventEdition = Array.isArray(eventEditionList.userExhibitingOrganisations) ?
        Utils.findCurrentEventEdition(eventEditionList.userExhibitingOrganisations, context.eventEditionId) : null;
      if (matchedEventEdition) {
        const previousTimestamp = parseInt(
          localStorage.getItem('participantSettingsDropdownCacheTimestamp'),
          10
        );
        const shouldUseCacheValue = Utils.shouldUsedCachedValue(previousTimestamp);
        if (shouldUseCacheValue) {
          this.setState({
            shouldShowEmperiaLeads: localStorage.getItem('shouldShowEmperiaLeads') === 'true',
            shouldShowExhibitorDashboard: localStorage.getItem('shouldShowExhibitorDashboard') === 'true',
            userPackage: localStorage.getItem('participantUserPackage') === 'true'
          });
        } else {
          const getEventEditionProperties =
            CommonServices.getEventEditionProperties(baseApiUrl, context.eventEditionId, context.interfaceLocale);
          const getEmperiaLeads = emperiaService.shouldShowEmperiaTile(
            baseApiUrl,
            context.eventEditionId,
            matchedEventEdition?.exhibitingOrganisationId,
            context.interfaceLocale
          );
          Promise.all([
            getEventEditionProperties,
            getEmperiaLeads
          ]).then((response) => {
            const eventEditionPropertiesResponse = response[0];
            const emperiaLeadsResponse = response[1];
            const shouldShowEmperiaLeads = Boolean(emperiaLeadsResponse?.showLeads);
            const shouldShowExhibitorDashboard = Boolean(eventEditionPropertiesResponse?.eventEdition?.exhibitorInsightsConfig?.enableDashboard);

            this.setState({
              shouldShowEmperiaLeads,
              shouldShowExhibitorDashboard
            });

            localStorage.setItem('shouldShowExhibitorDashboard', shouldShowExhibitorDashboard);
            localStorage.setItem('shouldShowEmperiaLeads', shouldShowEmperiaLeads);
            localStorage.setItem('participantUserPackage', matchedEventEdition.package);
          });
        }
        this.setState({
          isCurrentEEAdmin: true,
          userPackage: matchedEventEdition.package,
          adminExhibitingOrganisation: matchedEventEdition.exhibitingOrganisationId
        });
      }
      this.setState({
        isLoading: false
      });
    });
  }

  shouldUseCacheValue = (assets) => {
    const desktopImageUrl = localStorage.getItem('desktopUrl');
    const mobileImageUrl = localStorage.getItem('mobileUrl');
    if (!desktopImageUrl && !mobileImageUrl) {
      this.setState({
        profileImageNotAvailable: true
      });
    }

    this.setState({
      userName: localStorage.getItem('participantUserName'),
      userPackage: localStorage.getItem('participantUserPackage'),
      desktopUrl: desktopImageUrl || assets.defaultParticipantPhotoUrl,
      mobileUrl: mobileImageUrl || assets.defaultParticipantPhotoMobileUrl
    });            
  }

  shouldNotUseCacheValue = (context, assets, userParticipation, baseApiUrl, baseUrlPrivateAssets) => {
    const getUserProperties = UserProperties.getUserProperties({
      baseApiUrl,
      participationId: userParticipation[0].participationId,
      locale: context.interfaceLocale
    });
    const featureFilterColourCode = {};

    FeatureFilterService.getFeatureFilters(baseApiUrl, context.eventEditionId, 'en-GB').then((response) => {
      response.featureFilters.items.forEach((filter) => {
        featureFilterColourCode[filter.id] = filter.colourCode;
      });
      sessionStorage.setItem('eventEditionFeatureFilters', JSON.stringify(featureFilterColourCode));
    });

    Promise.all([
      getUserProperties
    ]).then((response) => {
      const { participation } = response[0];

      localStorage.setItem('participantSettingsDropdownCacheTimestamp', Date.now().toString());
      localStorage.setItem('participantUserName', participation.firstName);
      localStorage.setItem('participantUserPackage', participation.exhibitingOrganisation.package);
      this.setState({
        userName: participation.firstName,
        userPackage: participation.exhibitingOrganisation.package
      });
      if (!participation.photoKey) {
        this.setState({
          profileImageNotAvailable: true
        });
      }
      if (participation.photoKey) {
        CommonServices.getSignedAsset(baseApiUrl, participation.photoKey, context.interfaceLocale).then((photoUrl) => {
          const imageSources = Utils.getImageUrl(photoUrl, assets, true, baseUrlPrivateAssets);
          this.setState({
            desktopUrl: imageSources?.desktop,
            mobileUrl: imageSources?.mobile
          });
          localStorage.setItem('desktopUrl', imageSources?.desktop);
          localStorage.setItem('mobileUrl', imageSources?.mobile);
        });
      }
    });
  }

  hasUserParticipation = (userParticipation, shouldUseCacheValue, context, assets, baseApiUrl, baseUrlPrivateAssets) => {       
    if (shouldUseCacheValue) {
      this.shouldUseCacheValue(assets);
    } else {         
      this.shouldNotUseCacheValue(context, assets, userParticipation, baseApiUrl, baseUrlPrivateAssets);
    }
  }

  setParticipationsSateValue = (userParticipation) => {
    return (userParticipation && userParticipation.length>0) ? userParticipation[0] : null;
  }

  getUserParticipation = (context, navigation, baseApiUrl, userId, hasAdminClaim, assets, baseUrlPrivateAssets) => {
    UserService.getUserParticipation({
      baseApiUrl,
      eventEditionId: context.eventEditionId,
      userId,
      locale: context.interfaceLocale
    }).then(({ userParticipation = null }) => {
      this.setState({
        isLoading: false,
        participations: this.setParticipationsSateValue(userParticipation)
      });
      const previousTimestamp = parseInt(
        localStorage.getItem('participantSettingsDropdownCacheTimestamp'),
        10
      );
      const shouldUseCacheValue = Utils.shouldUsedCachedValue(previousTimestamp);

      if (userParticipation && userParticipation.length > 0) {       
        this.hasUserParticipation(userParticipation, shouldUseCacheValue, context, assets, baseApiUrl, baseUrlPrivateAssets);
      } else if (hasAdminClaim && Utils.getLocationHref() === navigation.customerHubUrlFormat) {
        Utils.setLocationHref(navigation.exhibitorHubUrlFormat);
      }
    });
  }

  handleClickOutside = (event) => {
    if (document.querySelector('.logged-in-user-settings__dropdown-base').contains(event.target)) {
      return;
    }
    if (!document.querySelector('.participant-dropdown-container').contains(event.target) && this.state.isNavOpened) {
      this.setState({ isNavOpened: !this.state.isNavOpened });
    }
  };

  setEditProfileLink = (context, participations, navigation) => {
    return participations && participations.participationId ?
      Utils.generateParticipantURL(
        navigation.participantProtectedProfile,
        participations.participationId,
        context.interfaceLocale
      ) : '';
  }

  render() {
    const {
      navigation,
      context,
      translation
    } = this.props;
    const { directory } = Classes.homePage;
    const { settings } = directory;
    const {
      isLoading,
      participations,
      userName,
      userPackage,
      isNavOpened,
      desktopUrl,
      mobileUrl,
      shouldShowExhibitorDashboard,
      shouldShowEmperiaLeads,
      isCurrentEEAdmin,
      adminExhibitingOrganisation,
      profileImageNotAvailable
    } = this.state;

    const updateDropDownDisplay = () => {
      this.setState({ isNavOpened: !isNavOpened });
    }; 

    const editProfileLink = this.setEditProfileLink(context, participations, navigation);
    const editCompanyProfileLink = isCurrentEEAdmin ?
      Utils.generateCompanyURL(adminExhibitingOrganisation, navigation.participantCompanyProtectedProfile) : '';

    const exHubLink = isCurrentEEAdmin ? navigation.exhibitorHubUrlFormat : '';

    const exhibitorDashboardUrl = isCurrentEEAdmin && userPackage !== 'BASIC' ?
      Utils.constructExhibitorDashboardUrl(navigation.exhibitorHubUrlFormat, context.eventEditionId, adminExhibitingOrganisation) : '';
    const emperiaLeadsUrl = isCurrentEEAdmin && userPackage !== 'BASIC' ?
      Utils.constructEmperiaLeadUrl(navigation.exhibitorHubUrlFormat, context.eventEditionId, adminExhibitingOrganisation) : '';
    const attribute = { participationId: participations && participations.participationId };
    const profileImageAlertPDEnabled = featureToggle.isFeatureEnabled(featureToggle.Keys.PROFILE_IMAGE_ALERT_PD);

    const profileImageSection = (profileImageNotAvailable, profileImageAlertPDEnabled, settings, translation) => {      
      return profileImageNotAvailable && profileImageAlertPDEnabled &&
        <li className={settings.dropdownItem}>
          <a
            href={editProfileLink}
            className={settings.dropdownLink}
            data-dtm={Tags.participantProfile.editProfile.addProfileImage}
            data-dtm-attributes={JSON.stringify(attribute)}
          >
            <span className={settings.missingProfileImageIcon} />
            <span className={settings.dropdownLabel}>{translation.settings.profileImageMissing}</span>
          </a>
        </li>      
    }

    const emperiaLeadsSection = (emperiaLeadsUrl, shouldShowEmperiaLeads, settings, translation) => {
      return (emperiaLeadsUrl && shouldShowEmperiaLeads) &&
      <li className={settings.dropdownItem}>
        <a href={emperiaLeadsUrl} className={`${settings.dropdownLink}`} data-dtm={Tags.participantProfile.editProfile.empLeads}>
          <span className={settings.empLeadsIcon}><EmperiaIcon /></span>
          <span className={settings.dropdownLabel}>{translation.settings.empLeads}</span>
        </a>
      </li>
    }


    const participantsSettingsDropdownJsx = () => (
      <div data-testid="participant-settings-wrapper" className={settings.dropdown}>
        <div
          className={`${settings.participantDropdownContainer} ${Classes.themeBgColor} ${isNavOpened && settings.topBorder}`}
          role="button"
          tabIndex={0}
          onClick={updateDropDownDisplay}
        >
          <div className={settings.participantThumbnail}>
            <Image
              mobileImageUrl={mobileUrl}
              desktopImageUrl={desktopUrl}
              maxWidth="30px"
              dtmTag={Tags.participantProfile.image}
              alt="user"
            />
          </div>
          <span className={settings.participantName}>
            {translation.settings.helloMessage}, {userName}
          </span>
          <div className={`${isNavOpened ? settings.upArrow : settings.downArrow}`} />
        </div>
        <div className={settings.dropdownBase}>
          <ul className={` ${isNavOpened ? settings.dropdownList : settings.listHide} ${Classes.themeBgColor}`} >
            {profileImageSection(profileImageNotAvailable, profileImageAlertPDEnabled, settings, translation)}
            {editProfileLink &&
            <li className={settings.dropdownItem}>
              <a href={editProfileLink} className={settings.dropdownLink} data-dtm={Tags.participantProfile.editProfile.participant}>
                <span className={settings.editProfileIcon} />
                <span className={settings.dropdownLabel}>{translation.settings.editProfile}</span>
              </a>
            </li>
            }
            {editCompanyProfileLink &&
            <li className={settings.dropdownItem}>
              <a href={editCompanyProfileLink} className={settings.dropdownLink} data-dtm={Tags.participantProfile.editProfile.company}>
                <span className={settings.editCompanyProfileIcon} />
                <span className={settings.dropdownLabel}>{translation.settings.editCompanyProfile}</span>
              </a>
            </li>
            }
            {exHubLink &&
            <li className={settings.dropdownItem}>
              <a href={exHubLink} className={settings.dropdownLink} data-dtm={Tags.participantProfile.editProfile.exHub}>
                <span className={settings.exHubIcon} />
                <span className={settings.dropdownLabel}>{translation.settings.exHub}</span>
              </a>
            </li>
            }
            {emperiaLeadsSection(emperiaLeadsUrl, shouldShowEmperiaLeads, settings, translation)}
            {(exhibitorDashboardUrl && shouldShowExhibitorDashboard) &&
              <li className={settings.dropdownItem}>
                <a
                  href={exhibitorDashboardUrl}
                  className={`${settings.dropdownLink}`}
                  data-dtm={Tags.participantProfile.editProfile.companyDashboard}
                >
                  <span className={settings.companyDashboardIcon} />
                  <span className={settings.dropdownLabel}>{translation.settings.companyDashboard}</span>
                </a>
              </li>
            }
            {
              <li className={settings.dropdownItem}>
                <a
                  href={navigation.logoutPath}
                  onClick={() => {
                    localStorage.removeItem('participantSettingsDropdownCacheTimestamp');
                    localStorage.removeItem('desktopUrl');
                    localStorage.removeItem('mobileUrl');
                  }}
                  className={`${settings.dropdownLink} ${settings.dropdownLink}--logout`}
                  data-dtm={Tags.participantProfile.editProfile.logout}
                >
                  <span className={settings.logoutIcon} />
                  <span className={settings.dropdownLabel}>{translation.settings.logout}</span>
                </a>
              </li>
            }
          </ul>
        </div>
      </div>
    );

    return (
      <div key={settings.dropdown+'--key'}>
          { isLoading ? (<SpinnerWithMessage className={settings.spinner} message={translation.loader.loading} />) :
            (
              <div key={settings.spinner+'--key'}>
                { participantsSettingsDropdownJsx() }
              </div>
            )
          }
      </div>
    );
  }
}

LoggedInUserSettings.propTypes = {
  navigation: PropTypes.object.isRequired,
  translation: PropTypes.object.isRequired,
  baseApiUrl: PropTypes.string.isRequired,
  context: PropTypes.object.isRequired,
  assets: PropTypes.object.isRequired,
  baseUrlPrivateAssets: PropTypes.string.isRequired
};

export default LoggedInUserSettings;
