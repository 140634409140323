export default {
  loginLink: 'participantdirectory_loginLink',
  search: {
    resultCount: 'participantdirectory_search_result_count'
  },
  participantDirectory: {
    searchBar: 'participantdirectory_searchBar',
    searchButton: 'participantdirectory_participantdirectory_searchButton',
    participantItem: 'participantdirectory_participantName',
    company: 'participantdirectory_companyDetail',
    image: 'participantdirectory_profileImage',
    sortOptions: {
      lastNameAsc: 'participantdirectory_ascendingsortbyLastname',
      lastNameDesc: 'participantdirectory_descendingsortbyLastname',
      createdDateTimeDesc: 'participantdirectory_sortbynewest',
      lastUpdatedDateTimeDesc: 'participantdirectory_sortbyRecentlyedited'
    },
    favouriteIcon: 'participantDirectory_participant_favourite',
    unFavouriteIcon: 'participantDirectory_participant_unfavourite',
    addToMyNetworkIcon: 'participantDirectory_participantnetworking_add',
    removeFromMyNetworkIcon: 'participantDirectory_participantnetworking_remove',
    phone: 'participantdirectory_phone',
    email: 'participantdirectory_email',
    emailCopy: 'participantdirectory_emailCopy',
    phoneCopy: 'participantdirectory_phoneCopy',
    feedbackButton: 'participantdirectory_feedbackButton'
  },
  participantList: {
    activationEmail: 'participantdirectory_participantList_activationEmail',
    searchBar: 'participantdirectory_participantList_searchBar',
    searchButton: 'participantdirectory_participantList_searchButton',
    participantItem: 'participantdirectory_participantList_participantName',
    company: 'participantdirectory_participantList_companyProfile',
    image: 'participantdirectory_participantList_profileImage',
    downloadParticipantReportButton: 'download_participant_report_button',
    description: 'participantdirectory_participantList_description'
  },
  participantProfile: {
    banner: 'participantdirectory_participant_banner',
    bannerActions: {
      save: 'participantdirectory_save_participant_banner',
      cancel: 'participantdirectory_cancel_participant_banner'
    },
    image: 'participantdirectory_participant_image',
    company: 'participantdirectory_participantProfile_organisationName',
    phone: 'participantdirectory_participantProfile_phone',
    email: 'participantdirectory_participantProfile_email',
    resendWelcomeEmail: 'participantdirectory_participantProfile_resend_welcome_email',
    profilePhoto: {
      deletePhoto: 'participantdirectory_participant_profile_photo_delete',
      uploadPhoto: 'participantdirectory_participant_profile_photo_upload'
    },
    description: {
      save: 'participantdirectory_participant_description_save',
      cancel: 'participantdirectory_participant_description_cancel',
      edit: 'participantdirectory_participant_description_edit'
    },
    editProfile: {
      participant: 'participantdirectory_participant_edit_profile',
      company: 'participantdirectory_company_edit_profile',
      exHub: 'participantdirectory_exHub_edit_profile',
      empLeads: 'participantdirectory_leads_report_navigation',
      companyDashboard: 'participantdirectory_company_dashboard',
      logout: 'participantdirectory_logout',
      addProfileImage: 'participantdirectory_participant_add_profile_image'
    },
    settings: {
      edit: 'participantdirectory_participant_settings_edit',
      participantActive: 'participantdirectory_partipant_settings_participantStatus',
      save: 'participantdirectory_participant_settings_save',
      cancel: 'participantdirectory_participant_settings_cancel'
    },
    contactPrivacy: {
      edit: 'participantdirectory_participant_contactInformation_edit',
      optIn: 'participantdirectory_participanProfile_opt_in',
      optOut: 'participantdirectory_participanProfile_opt_out'
    },
    socialMedia: {
      FACEBOOK: 'participantdirectory_participantProfile_follow_facebook',
      TWITTER: 'participantdirectory_participantProfile_follow_twitter',
      YOUTUBE: 'participantdirectory_participantProfile_follow_youtube',
      LINKEDIN: 'participantdirectory_participantProfile_follow_linkedin',
      WECHAT: 'participantdirectory_participantProfile_follow_wechat'
    },
    editableParticipantInfo: {
      FIRSTNAME: 'participantdirectory_participant_firstname_edit',
      MIDDLENAME: 'participantdirectory_participant_middlename_edit',
      LASTNAME: 'participantdirectory_participant_lastname_edit',
      JOBTITLE: 'participantdirectory_participant_jobtitle_edit',
      COMPANY: 'participantdirectory_participant_company_edit'
    },
    editableContactInfo: {
      PHONE: 'participantdirectory_participant_phone_number_edit'
    },
    editableSocialMedia: {
      FACEBOOK: 'participantdirectory_participantProfile_socialMedia_facebookUrl',
      TWITTER: 'participantdirectory_participantProfile_socialMedia_twitterUrl',
      YOUTUBE: 'participantdirectory_participantProfile_socialMedia_youtubeUrl',
      LINKEDIN: 'participantdirectory_participantProfile_socialMedia_linkedinUrl',
      WECHAT: 'participantdirectory_participantProfile_socialMedia_wechatUrl'
    },
    navigateToDirectory: 'participantdirectory_participantProfile_navigate_directory',
    profileHeader: 'participantdirectory_participantProfile_profileHeader_switchLanguage'
  },
  participantDetails: {
    company: 'participantdirectory_participantDetail_organisationName',
    phone: 'participantdirectory_participantDetail_phone',
    email: 'participantdirectory_participantDetail_email',
    emailCopy: 'participantdirectory_participant_emailCopy',
    phoneCopy: 'participantdirectory_participant_phoneCopy',
    sendMessage: 'participantdirectory_participantDetail_sendMessage',
    socialMedia: {
      FACEBOOK: 'participantdirectory_participantDetail_follow_facebook',
      TWITTER: 'participantdirectory_participantDetail_follow_twitter',
      YOUTUBE: 'participantdirectory_participantDetail_follow_youtube',
      LINKEDIN: 'participantdirectory_participantDetail_follow_linkedin',
      WECHAT: 'participantdirectory_participantDetail_follow_wechat'
    },
    navigateToDirectory: 'participantdirectory_participantDetail_navigate_directory',
    favouriteIcon: 'participantDirectory_participantDetail_participant_favourite',
    unFavouriteIcon: 'participantDirectory_participantDetail_participant_unfavourite',
    addToMyNetworkIcon: 'participantdirectory_participantDetail_participantnetworking_add',
    removeFromMyNetworkIcon: 'participantdirectory_participantDetail_participantnetworking_remove',
    ppsAnswers: 'participantdirectory_participantDetail_PPS_Answers'
  },
  companyProfile: {
    navigateToDirectory: 'participantdirectory_companyProfile_navigate_directory'
  },
  socialMediaForm: {
    save: 'participantdirectory_participant_socialMedia_save',
    cancel: 'participantdirectory_participant_socialMedia_cancel'
  },
  editProfileQuestions: {
    loginLink: 'participantdirectory_participantProfile_matchmaking_loginLink',
    edit: 'participantdirectory_participantProfile_matchmaking_edit',
    save: 'participantdirectory_participantProfile_matchmaking_save',
    cancel: 'participantdirectory_participantProfile_matchmaking_cancel',
    matchmaking: 'participantdirectory_participantProfile_matchmaking_matchmaking',
    matchmakingEdit: 'participantdirectory_participantProfile_matchmaking_clickableMatchmaking'
  },
  featureFilters: {
    delete: 'delete_participant_filter',
    edit: 'edit_participant_filter'
  }
};
