/* eslint-disable no-useless-escape */
import jsonwebtoken from 'jsonwebtoken';
import path from 'path';
import find from 'lodash/find';
import intersection from 'lodash/intersection';
import { resolve as urlResolve } from 'url';
import api from './api/fetch';
import {
  ImageFileExtensions,
  Modes,
  SocialMediaKeys,
  ReactSettings,
  RegRedirectConstants
} from './Constants';
import StringFormatter from './StringFormatter';
import ServiceResultFactory from '../common/api/ServiceResultFactory';
import appConfigurations from './appConfiguration';
import featureToggles from './featureToggle/featureToggles';
import authorizationStore from './api/authorizationStore';
import UserService from './api/UserService';

const filterFieldErrors = (fieldErrors, fieldNameRegex) => {
  const errors = [];
  Object.keys(fieldErrors).forEach((key) => {
    if (Object.prototype.hasOwnProperty.call(fieldErrors, key) && key.match(fieldNameRegex)) {
      errors.push(...fieldErrors[key]);
    }
  });
  return errors;
};

const isRxAdmin = mode => mode === Modes.private;

const setLocationHref = (url) => {
  window.location.href = url;
};

const windowOjbect = global.window || window;

const getReactSettings = () => windowOjbect[ReactSettings.participantProfile]
  || windowOjbect[ReactSettings.participantDetails]
  || windowOjbect[ReactSettings.participantList]
  || windowOjbect[ReactSettings.participantDirectory]
  || windowOjbect[ReactSettings.featureFilters]
  || windowOjbect[ReactSettings.default]
  || windowOjbect[ReactSettings.reactSettingsParticipantSettings]
  || windowOjbect[ReactSettings.hybridAuthRedirect];

const addDomainPrefix = (url, baseUrl) => {
  let prefix = appConfigurations.getAssetSource();
  if (featureToggles
    .isFeatureEnabled(featureToggles.Keys.CDN_URL_IN_REACTSETTINGS)) {
    prefix = baseUrl || appConfigurations.getAssetSource();
  }

  return `${prefix}${url.charAt(0) === '/' ? '' : '/'}${url}`;
};

const getCurrentEnvironment = () => {
  let env = (global.window || window) ?
    getReactSettings().environment :
    global.process.env.environment;
  if (!env) {
    env = 'prod';
  }
  return env;
};

const isDevEnvironment = environment => environment === 'localAgainstDevPkce' || environment === 'localAgainstDev'
  || environment === 'dev' || environment === 'localAgainstShow';

const reloadLocation = (e) => {
  e.preventDefault();
  e.stopPropagation();
  window.location.reload(true);
};

const getLocationHref = () => window.location.href;

const getResizedImage = (url, device, size) => {
  const extName = path.extname(url);
  const fileName =
    extName === '.gif'
      ? `${path.basename(url, extName)}.png`
      : path.basename(url);
  return urlResolve(url, path.join(`${device}-${size}`, fileName));
};

const retry = (functionToRetry, retryTimes = 15, passingCondition) => new Promise((resolve, reject) => {
  const firstRetryAfterMs = 200;
  const increaseWaitByFactor = 1.4;
  let times = retryTimes;
  const p = waitTimeInMs => functionToRetry().then((r) => {
    times -= 1;
    if ((r.ok === true
      || (r.ok === undefined && r.errors === undefined)) && (!passingCondition || passingCondition(r))) {
      resolve(r);
    } else if (times === 0) {
      reject(new Error('timeout'));
    } else {
      setTimeout(p, waitTimeInMs, waitTimeInMs * increaseWaitByFactor);
    }
  });
  p(firstRetryAfterMs);
});

const poll = (url, retryTimes) => retry(() => api.fetch(url, { method: 'GET' }), retryTimes);

const hasImageExtension = (fileName) => {
  const extension = fileName.toLowerCase().split('.').pop();
  return ImageFileExtensions.includes(extension);
};

const formatBytes = (bytes, decimals = 0) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  const convertedBytesInSize = (bytes / (k ** i)).toFixed(decimals);

  return `${convertedBytesInSize} ${sizes[i]}`;
};

const serviceResultHasError = (result) => {
  const { fieldErrors, errors, mustLogIn } = result;
  return Object.keys(fieldErrors).length !== 0 || errors.length !== 0 || mustLogIn;
};

const getTranslatedOrDefault = (translations, key) => (translations[key] ? translations[key] : key);

const commaSeparateElements = elements => elements.reduce((prev, curr) => [prev, ', ', curr]);

const findByLocale = (multilingual, locale) => {
  const lowerCaseLocale = locale.toLowerCase();
  return find(multilingual, o => o.locale.toLowerCase() === lowerCaseLocale);
};

const getLanguageCodeFromLocale = locale => locale.split('-')[0].toLowerCase();

const isDefined = obj => typeof obj !== 'undefined' && obj !== null;

const generateParticipantURL = (detailUrl, participationId, interfaceLocale) =>
  StringFormatter.format(detailUrl, participationId, interfaceLocale);

const generateCompanyURL = (exhibitingOrganisationId, detailUrl) =>
  StringFormatter.format(detailUrl, exhibitingOrganisationId);

const getImageUrl = (sourceSet = [], assets, shouldHaveDefaultImage = false, baseUrl) => {
  const defaultImages = assets;
  let mobileUrl = [];
  let desktopUrl = [];
  if (!Object.prototype.hasOwnProperty.call(sourceSet, 'errors')) {
    mobileUrl = sourceSet.filter(source => source.key.indexOf('/mobile/') > -1);
    desktopUrl = sourceSet.filter(source => source.key.indexOf('/desktop/') > -1);
  }


  const defaultImage = shouldHaveDefaultImage ?
    { mobile: defaultImages.defaultParticipantPhotoMobileUrl, desktop: defaultImages.defaultParticipantPhotoUrl }
    :
    null;

  return mobileUrl.length && mobileUrl[0].url && desktopUrl[0].url ? {
    mobile: addDomainPrefix(mobileUrl[0].url, baseUrl),
    desktop: addDomainPrefix(desktopUrl[0].url, baseUrl)
  } : defaultImage;
};

const getMergedSocialMedia = (socialMedia = []) => {
  const clonedSocialMediaKeys = SocialMediaKeys.map((SocialMediaKey) => {
    let url = '';
    socialMedia.forEach((item) => {
      if (item.socialMediaType === SocialMediaKey) {
        url = item.url; /* eslint-disable-line */
      }
    });
    return { socialMediaType: SocialMediaKey, url, error: null };
  });
  return clonedSocialMediaKeys;
};

const convertToRingboxString = obj =>
  (JSON.stringify(obj)
    .replace(/\"socialMediaType"/g, 'socialMediaType')
    .replace(/\"url"/g, 'url')
    .replace(/\"TWITTER"/g, 'TWITTER')
    .replace(/\"YOUTUBE"/g, 'YOUTUBE')
    .replace(/\"FACEBOOK"/g, 'FACEBOOK')
    .replace(/\"WECHAT"/g, 'WECHAT')
    .replace(/\"LINKEDIN"/g, 'LINKEDIN')
  );

// const escapeTextData = text => escapeJSON(escapeString(text));// .replace('\'', 'A');//.replace(/\\'/, '');
const escapeTextData = text => encodeURI(text);// .replace('\'', 'A');//.replace(/\\'/, '');

// const escapeTextData = string => string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string

// const generateIndex = (data) => {
//   let index = "";


//   let processItem = (inputArray) => {

//   }
// }

const formErrors = (locale, errorCode) =>
  ServiceResultFactory.fromErrorCode(locale, errorCode).errors.map(message => ({ code: errorCode, message, locale }));

const convertToPPSQuestions = (data) => {
  data.forEach((i) => {
    const item = i;
    item.id = item.id || '';
    item.text = item.text || '';
    item.tags = item.tags || [];
    item.sortOrder = item.sortOrder || 0;
    item.instructionalText = item.instructionalText || '';
    item.maximumResponses = item.maximumResponses || 0;
    item.minimumResponses = item.minimumResponses || 0;
  });
  return data;
};

const convertToPPSAnswers = data =>
  data.map(item => (
    {
      responses: item.answers.map(answer => ({ answerId: answer.id })),
      questionId: item.id || item.questionId || ''
    }
  ));

const getSelectedAnswers = (profileAnswers) => {
  const data = {};
  profileAnswers.forEach((question) => {
    data[question.questionId] = question.responses;
  });
  return data;
};

const getProfilingQuestions = (data, includes, excludes, mandatoryTags) => {
  const matchMakingData = [];
  const profileData = [];

  data.filter((item) => {
    const tags = item.tags.join(',').toLowerCase().split(',');
    if (intersection(tags, includes).length === includes.length && intersection(tags, excludes).length === 0) {
      matchMakingData.push(item);
    } else if (intersection(tags, mandatoryTags).length === mandatoryTags.length) {
      profileData.push(item);
    }
    return item;
  });
  return {
    matchMakingData: {
      ppsQuestions: matchMakingData
    },
    profileData: {
      ppsQuestions: profileData
    }
  };
};

const isMinimumAnswerSelected = (data, selected) => data.filter((item) => {
  const questionId = item.id;
  const minimumRequiredResponses = item.minimumResponses;
  const maximumRequiredResponses = item.maximumResponses;

  const totalSelectedAnswers = selected[questionId] ? selected[questionId].length : 0;

  if (minimumRequiredResponses === 0 || maximumRequiredResponses === 0) {
    if (minimumRequiredResponses === 0 && maximumRequiredResponses === 0) {
      return false;
    }
    return minimumRequiredResponses > 0 ?
      !(minimumRequiredResponses <= totalSelectedAnswers) :
      !(maximumRequiredResponses >= totalSelectedAnswers);
  }
  return !((minimumRequiredResponses <= totalSelectedAnswers) &&
    (maximumRequiredResponses >= totalSelectedAnswers));
}).map(i => i.id);

const getFromSessionStorage = key => JSON.parse(windowOjbect.sessionStorage.getItem(key));

const constructRegRedirectURL = () => {
  const environment = getCurrentEnvironment();
  const regRedirectPath = isDevEnvironment(environment) ? RegRedirectConstants.REG_REDIRECT_URL_DEV
    : `${window.location.origin}${RegRedirectConstants.REG_REDIRECT_URL}`;
  return regRedirectPath;
};

const constructPkceRedirectionUrl = (redirectUrl, productType) => {
  const host = window.location.origin;
  const state = {
    [RegRedirectConstants.REDIRECT_URL]: Buffer.from(redirectUrl).toString('base64'),
    [RegRedirectConstants.PRODUCT_TYPE]: productType
  };
  const environment = getCurrentEnvironment();
  const url = isDevEnvironment(environment) ? RegRedirectConstants.PKCE_REDIRECT_URL_DEV
    : `${host}${RegRedirectConstants.PKCE_SIGNUP_REDIRECT_URL}`;
  return `${url}&state=${JSON.stringify(state)}`;
};

const constructEmperiaLeadUrl = (baseurl, eventEditionId, exhibitorId) => {
  const url = `lead-manager.${eventEditionId}:${exhibitorId}.html`;

  if (baseurl.includes('.html#')) {
    return baseurl.replace('.html#', '') + url;
  }
  return baseurl.replace('.html', '/') + url;
};

const constructExhibitorDashboardUrl = (baseurl, eventEditionId, exhibitorId) =>
  `${baseurl.endsWith('/') ? baseurl : baseurl.concat('#/')}exhibitor-insights/${eventEditionId}/${exhibitorId}`;

const constructLoginEmailURL = (idpUrl, knownUrl, unknownUrl, eventEditionId, locale) =>
  `${idpUrl.endsWith('/') ? idpUrl : idpUrl.concat('/')}${RegRedirectConstants.LOGIN_EMAIL_PATH}?KnownUrl=${knownUrl}`
  + `&unknownUrl=${unknownUrl}&eventEditionId=${eventEditionId}&locale=${locale}`;

const findCurrentEventEdition = (eventEditionList, currentEventEdition) =>
  eventEditionList.find(eventEdition => eventEdition.eventEditionId === currentEventEdition);

const shouldUsedCachedValue = previousTimestamp => previousTimestamp && (Date.now() - previousTimestamp) / 60000 <= 5;

const removeDuplicateSlashInUrl = (url) => {
  if (!url) {
    return url;
  }

  return url.replace(/([^:]\/)\/+/g, '$1');
};

const getParticipationId = async (context, baseApiUrl) => {
  const isAdmin = isRxAdmin(context.mode);
  if (isAdmin) {
    return context.participant.participationId;
  }
  const token = await authorizationStore.getToken();
  const userId = jsonwebtoken.decode(token).sub;

  const response = await UserService.getUserParticipation({
    baseApiUrl,
    eventEditionId: context.eventEditionId,
    userId,
    locale: context.interfaceLocale
  });

  return response.userParticipation ? response.userParticipation[0]?.participationId : null;
};

export default {
  hasImageExtension,
  poll,
  getResizedImage,
  filterFieldErrors,
  setLocationHref,
  getLocationHref,
  reloadLocation,
  formatBytes,
  isRxAdmin,
  serviceResultHasError,
  getTranslatedOrDefault,
  commaSeparateElements,
  findByLocale,
  getLanguageCodeFromLocale,
  isDefined,
  generateParticipantURL,
  generateCompanyURL,
  getImageUrl,
  getMergedSocialMedia,
  convertToRingboxString,
  escapeTextData,
  formErrors,
  convertToPPSQuestions,
  convertToPPSAnswers,
  getSelectedAnswers,
  getProfilingQuestions,
  isMinimumAnswerSelected,
  getCurrentEnvironment,
  isDevEnvironment,
  addDomainPrefix,
  retry,
  getFromSessionStorage,
  constructRegRedirectURL,
  constructLoginEmailURL,
  constructPkceRedirectionUrl,
  constructExhibitorDashboardUrl,
  constructEmperiaLeadUrl,
  findCurrentEventEdition,
  shouldUsedCachedValue,
  getReactSettings,
  removeDuplicateSlashInUrl,
  getParticipationId
};
