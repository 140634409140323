/* global window:true */

import Uri from './uri';
import api from './fetch';
import authorizationStore from './authorizationStore';

const httpVerb = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE'
};

const status = {
  OK: 200,
  BAD_REQUEST: 400
};

const acceptType = 'application/json';
const contentType = 'application/json';

const mergeHeaders = async (customHeaders, isAuthorized = true) => {
  const clientId = authorizationStore.getClientId();
  const xClientId = clientId ? { 'X-ClientId': clientId } : {};
  const token = !isAuthorized ? null : await authorizationStore.getToken();
  const bearer = (token && isAuthorized) ? { Authorization: `Bearer ${token}` } : {};
  return Object.assign({ Accept: acceptType }, bearer, xClientId, customHeaders);
};

const performFetch = async (url, method, body, customHeaders = {}) => {
  const headers = await mergeHeaders(customHeaders);
  return api.fetch(url, { method, headers, body });
};

const shouldReject = response => response.status >= status.BAD_REQUEST;

const shouldRejectGraphql = response => response.status >= status.BAD_REQUEST || (response.errors && response.errors.length > 0);

const extractStatusAndBody = resp => resp
  .json()
  .then(body => ({ status: resp.status, body }));

const extractStatusAndBodyData = resp => resp
  .json()
  .then(body => ({ status: resp.status, body: body.data, errors: body.errors }));

const extractFileData = resp => resp
  .text()
  .then((data) => {
    const name = resp.headers.get('Content-Disposition').split('attachment; filename=')[1];
    const type = resp.headers.get('Content-Type');
    return { status: resp.status, file: { name, data, type } };
  });

const processFileReponse = (resp) => {
  if (shouldReject(resp)) {
    return extractStatusAndBody(resp);
  }
  return extractFileData(resp);
};

const resultForStatus = (result) => {
  if (shouldReject(result)) {
    return Promise.reject(result);
  }
  return result;
};

const resultForStatusGraphql = (result) => {
  if (shouldRejectGraphql(result)) {
    return Promise.reject(result);
  }
  return result;
};

const resultWithNoSuccessBody = (response) => {
  if (shouldReject(response)) {
    return extractStatusAndBody(response)
      .then(resultForStatus);
  }
  if (response.headers && response.headers.get('Location')) {
    return { status: response.status, location: response.headers.get('Location') };
  }
  return { status: response.status };
};

const resultWithSuccessBody = (response) => {
  if (shouldReject(response)) {
    return extractStatusAndBody(response)
      .then(resultForStatus);
  }
  if (response.headers && response.headers.get('Location')) {
    return extractStatusAndBody(response)
      .then(data => ({ ...data, location: response.headers.get('Location') }));
  }
  return extractStatusAndBody(response);
};
const getRequest = (url, params) => {
  const urlWithParams = Uri.withParams(url, params);
  return performFetch(urlWithParams, httpVerb.GET)
    .then(extractStatusAndBody)
    .then(resultForStatus);
};

const getFileRequest = (url, params) => {
  const urlWithParams = Uri.withParams(url, params);
  return performFetch(urlWithParams, httpVerb.GET)
    .then(processFileReponse)
    .then(resultForStatus);
};

const requestWithBody = (verb, url, body, params = {}, headers = {}) => {
  const urlWithParams = Uri.withParams(url, params);

  return performFetch(urlWithParams, verb, body, headers)
    .then(resultWithNoSuccessBody);
};

const requestWithBodyAndReturnBody = (verb, url, body, params = {}, headers = {}) => {
  const urlWithParams = Uri.withParams(url, params);

  return performFetch(urlWithParams, verb, body, headers)
    .then(resultWithSuccessBody);
};

const deleteRequest = (url, params) => {
  const urlWithParams = Uri.withParams(url, params);
  return performFetch(urlWithParams, httpVerb.DELETE)
    .then(resultWithNoSuccessBody);
};

const createFileBody = (file) => {
  const formData = new window.FormData();
  formData.append('file', file);
  return formData;
};

const requestWithFile = verb => (url, body, params) =>
  requestWithBodyAndReturnBody(verb, url, createFileBody(body), params);

const requestWithJson = verb => (url, body, params) => {
  const customHeaders = { 'Content-Type': contentType };
  return requestWithBody(
    verb, url, JSON.stringify(body),
    params, customHeaders
  );
};

const graphqlRequest = async (url, query, variables, isAuthorized = true) => {
  const customHeaders = { 'Content-Type': contentType };
  const body = JSON.stringify({
    query,
    variables
  });
  const headers = await mergeHeaders(customHeaders, isAuthorized);
  return fetch(`${url}graphql/`, { method: httpVerb.POST, headers, body })
    .then(extractStatusAndBodyData)
    .then(resultForStatusGraphql);
};

export default {
  get: getRequest,
  getFile: getFileRequest,
  put: requestWithJson(httpVerb.PUT),
  putFile: requestWithFile(httpVerb.PUT),
  post: requestWithJson(httpVerb.POST),
  postFile: requestWithFile(httpVerb.POST),
  delete: deleteRequest,
  graphqlRequest,
  shouldReject
};
