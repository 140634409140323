import enGB from './en-GB.json';
import frFR from './fr-FR.json';
import itIT from './it-IT.json';
import thTH from './th-TH.json';
import ptBR from './pt-BR.json';
import esMX from './es-MX.json';

const localeMap = {
  'en-GB': enGB,
  'fr-FR': frFR,
  'it-IT': itIT,
  'th-TH': thTH,
  'pt-BR': ptBR,
  'es-MX': esMX
};

export default {
  getTranslations(locale) {
    const translationsForLocale = localeMap[locale];
    return translationsForLocale || localeMap['en-GB']; // Default to english
  }
};
