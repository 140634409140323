import React from 'react';
import PropTypes from 'prop-types';
import BigCircleSpinner from './BigCircleSpinner';
import Classes from '../Classes';

const SpinnerWithMessage = ({
  className = '', message, isBig, stretchable = false
}) => {
  const { spinner } = Classes;
  const parentClassName = isBig ? `${className} ${spinner.root} ${spinner.bigSpinner}` : `${className} ${spinner.root}`;
  return (
    stretchable ?
      <div data-testid="spinner-with-message" className={spinner.stretchableSpinner}>
        <div className={parentClassName}>
          <BigCircleSpinner />
          <span className={spinner.message}>{message}</span>
        </div>
      </div> :
      <div data-testid="spinner-with-message-default" className={parentClassName}>
        <BigCircleSpinner />
        <span className={spinner.message}>{message}</span>
      </div>
  );
};

SpinnerWithMessage.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
  isBig: PropTypes.bool,
  stretchable: PropTypes.bool
};

export default SpinnerWithMessage;
