import Request from '../common/api/request';
import ServiceResultFactory from '../common/api/ServiceResultFactory';

const getFeatureFilters = (baseApiUrl, eventEditionId, locale) => {
  const query = `{
  featureFilters(eventEditionId: "${eventEditionId}", locale: "${locale}") 
 {
    items{
      id,
      multilingualNames{
        name,
        locale
      },
      colourCode
    }
  }
}`;

  return Request.graphqlRequest(baseApiUrl, query)
    .then(response => response.body)
    .catch((response) => {
      throw ServiceResultFactory.error.constructLocalizedErrorMessage(locale, response.errors.map(error => error.extensions.code));
    });
};

const addFeatureFilter = (baseApiUrl, eventEditionId, multilingualName) => {
  const mutation = `mutation{
  addFeatureFilter(eventEditionId: "${eventEditionId}", multilingual: {
      name: "${multilingualName}"
      locale: "en-GB"
    }){
      id
  }
}`;
  return Request.graphqlRequest(baseApiUrl, mutation)
    .then(response => ServiceResultFactory.fromSuccess(response.body))
    .catch(error => ServiceResultFactory.fromError('en-GB', { body: { errors: error.errors } }));
};

const deleteFeatureFilter = (baseApiUrl, eventEditionId, featureFilterId) => {
  const mutation = `mutation{
  deleteFeatureFilter(featureFilterId: "${featureFilterId}", eventEditionId: "${eventEditionId}")
}`;
  return Request.graphqlRequest(baseApiUrl, mutation)
    .then(response => ServiceResultFactory.fromSuccess(response.body))
    .catch(error => ServiceResultFactory.fromError('en-GB', { body: { errors: error.errors } }));
};

const editFeatureFilter = (baseApiUrl, eventEditionId, featureFilterId, multilingualName) => {
  const mutation = `mutation{
  updateFeatureFilter(featureFilterId: "${featureFilterId}", eventEditionId: "${eventEditionId}", multilingual: {
      name: "${multilingualName}"
      locale: "en-GB"
    }){
      id
  }
}`;
  return Request.graphqlRequest(baseApiUrl, mutation)
    .then(response => ServiceResultFactory.fromSuccess(response.body))
    .catch(error => ServiceResultFactory.fromError('en-GB', { body: { errors: error.errors } }));
};

const refreshAlgoliaIndex = (baseApiUrl, eventEditionId, locale, featureFilterId) => {
  const mutation = `mutation{
    refreshAlgoliaIndex(eventEditionId: "${eventEditionId}",locale: "en-GB" ,featureFilterId: "${featureFilterId}")
  }`;
  return Request.graphqlRequest(baseApiUrl, mutation)
    .then(response => ServiceResultFactory.fromSuccess(response.body))
    .catch(error => ServiceResultFactory.fromError('en-GB', { body: { errors: error.errors } }));
};

export default {
  getFeatureFilters, addFeatureFilter, deleteFeatureFilter, editFeatureFilter, refreshAlgoliaIndex
};
