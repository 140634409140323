import React from 'react';
import PropTypes from 'prop-types';

const Image = ({
  mobileImageUrl, desktopImageUrl, maxWidth, dtmTag, alt, dataDtmAttributes = '', title = '', className = ''
}) => (
  <picture data-testid="picture">
    <source data-testid="source" srcSet={`${mobileImageUrl}`} media={maxWidth} />
    <source srcSet={`${desktopImageUrl}`} />
    <img alt={alt} data-dtm={dtmTag} data-dtm-attributes={dataDtmAttributes} src={`${desktopImageUrl}`} className={className} title={title} />
  </picture>
);

Image.propTypes = {
  mobileImageUrl: PropTypes.string.isRequired,
  desktopImageUrl: PropTypes.string.isRequired,
  maxWidth: PropTypes.string.isRequired,
  dtmTag: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  title: PropTypes.string,
  className: PropTypes.string,
  dataDtmAttributes: PropTypes.string
};

export default Image;
