import TranslationService from '../../translations';

const clientError = (errorTranslations, isSaving, error) => ({
  debug: error,
  errors: [isSaving ? errorTranslations.client_error_saving : errorTranslations.client_error],
  fieldErrors: {}
});

const isUnauthorized = res =>
  res.body &&
  res.status === 401 &&
  res.body.errors[0].code === 'unauthorized';

const errorMapper = {
  mapErrors: (errorTranslations, errors, isSaving) => errors.map((error) => {
    const translationKey = isSaving ? `${error.code}_saving` : error.code;
    return errorTranslations[translationKey] || errorTranslations[error.extensions.code];
  }),

  errorMessageKey: (code, fieldName) => {
    const arraySelector = /(\W\d+\W)?/g;
    const allDots = /\./g;
    const formattedName = fieldName.replace(arraySelector, '').replace(allDots, '_');
    return `${code}_${formattedName}`;
  },

  mapFieldError: (errorTranslations, fieldError, prefix) => {
    const errorMessages = fieldError.errors.map((error) => {
      const prefixedFieldName = prefix ? `${prefix}_${fieldError.name}` : fieldError.name;
      const messageKey = errorMapper.errorMessageKey(error.code, prefixedFieldName);
      return errorTranslations[messageKey];
    });

    return { [fieldError.name]: errorMessages };
  },

  getFieldErrors: (fieldValidationErrors = []) => {
    const fieldErrorsFromResponse = [];
    fieldValidationErrors.forEach(x => x.errors.forEach((y) => {
      fieldErrorsFromResponse.push(Object.prototype.hasOwnProperty.call(y, 'propertyName') ?
        { name: y.propertyName, errors: [y] } :
        { name: x.name, errors: x.errors });
    }));
    return fieldErrorsFromResponse;
  },

  mapFieldErrors: (errorTranslations, fieldErrors, prefix) =>
    fieldErrors
      .map(fieldError => errorMapper.mapFieldError(errorTranslations, fieldError, prefix))
      .reduce((accumulator, fieldError) => Object.assign({}, accumulator, fieldError), {}),

  mapResponse: (errorTranslations, result, isSaving, prefix) => ({
    debug: result,
    errors: errorMapper.mapErrors(errorTranslations, result.errors, isSaving),
    fieldErrors: errorMapper.mapFieldErrors(
      errorTranslations,
      errorMapper.getFieldErrors(result.fieldValidationErrors), prefix
    )
  }),

  mapValidatorErrorForField: (errorTranslations, validatorErrorsForField, fieldName) => {
    const translatedErrors = validatorErrorsForField
      .map(errorCode => errorTranslations[errorMapper.errorMessageKey(errorCode, fieldName)]);
    return { [fieldName]: translatedErrors };
  },

  mapValidatorErrors: (errorTranslations, validatorErrors) => {
    const fields = Object.keys(validatorErrors);
    return fields
      .map(fieldName => errorMapper.mapValidatorErrorForField(
        errorTranslations,
        validatorErrors[fieldName],
        fieldName
      ))
      .reduce((accumulator, validatorError) => Object.assign({}, accumulator, validatorError), {});
  }
};

const ServiceResultFactory = {
  fromSuccess(data) {
    return {
      ...data, mustLogIn: false, errors: [], fieldErrors: {}, ok: true
    };
  },
  error: {
    constructLocalizedErrorMessage: (interfaceLocale, errorCodes) => {
      const errorTranslations = TranslationService.getTranslations(interfaceLocale).errorMessages;
      return errorCodes.map(code => ({ code, message: errorTranslations[code], locale: interfaceLocale }));
    }
  },
  fromError(locale, result, isSaving, prefix) {
    const errorTranslations = TranslationService.getTranslations(locale).errorMessages;

    if (isUnauthorized(result)) {
      return { mustLogIn: true, errors: [], fieldErrors: {} };
    }
    return result.body
      ? errorMapper.mapResponse(errorTranslations, result.body, isSaving, prefix)
      : clientError(errorTranslations, isSaving, result);
  },
  fromErrorCode(locale, errorCode) {
    const errorTranslations = TranslationService.getTranslations(locale).errorMessages;
    return {
      errors: [errorTranslations[errorCode]],
      fieldErrors: {}
    };
  },
  fromValidator(locale, validatorErrors) {
    const errorTranslations = TranslationService.getTranslations(locale).errorMessages;
    return {
      errors: [],
      fieldErrors: errorMapper.mapValidatorErrors(errorTranslations, validatorErrors)
    };
  },
  fromGraphQlError(locale, errors) {
    const errorCodes = errors.map(error => error.extensions.code);
    if (errorCodes.includes('unauthorized')) {
      return { isAuthenticated: false, errors: [] };
    }
    const translations = TranslationService.getTranslations(locale);
    const errorTranslations = translations.errorMessages;
    const errorMessages = errorCodes.map(code => (errorTranslations[code]));
    return { isAuthenticated: true, errors: errorMessages };
  }
};

export default ServiceResultFactory;
