import React from 'react';
import classnames from 'classnames';
import Classes from '../Classes';
import CircleSpinner from './CircleSpinner';

const BigCircleSpinner = () => {
  const { spinnerWrapper = '', spinnerWrapperBig = '', spinnerWrapperFixed = '' } = Classes.circleSpinner;
  return (
    <span data-testid="Big-Circle-Spinner" className={classnames(spinnerWrapper, spinnerWrapperBig, spinnerWrapperFixed)}>
      <CircleSpinner />
    </span>
  );
};

export default BigCircleSpinner;
