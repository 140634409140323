import React from 'react';
import 'regenerator-runtime/runtime';
import { createRoot } from 'react-dom/client';
import ParticipantSettings from '../../src/js/participant-settings/ParticipantSettings';
import '../../src/styles/mixins.less';
import { authController } from '@reedexpo/authorization-component-ui';
import authorizationStore from '../../src/js/common/api/authorizationStore';


export default ParticipantSettings;

function renderComponent(props, testVariants, elementId) {
  const container = document.getElementById(elementId);
  const root = createRoot(container);
  root.render(<ParticipantSettings {...props} testVariants={testVariants} />);
}


if (global.window && global.window.document) {
  const { props, elementId } = window.reactSettingsParticipantSettings;
  const testVariants = window.testVariants;

  const authSettings = window.authSettings;
  authController.initialize(authSettings);
  authorizationStore.getToken()
    .then((token) => {
      if (token) {
        renderComponent(props, testVariants, elementId);
      }
    })
    .catch(() => null);
}
